<template>
    <div>
            <div class="sidebar-wrapper">
                <SideNav feather="edit" :action="action" />
            </div>
            <div class="p-3">
                <div class="p-3 px-4" >
                    <h3 class="title is-4 "  v-if="getUser">
                        {{ getUser.firstname+' '+getUser.lastname }}
                    </h3>
                    <h6  v-if="getUser" class="subtitle ">{{ getUser.online ? 'You are online' : 'Your\'re Offline' }}</h6>
                    <!-- <h6  v-if="getUser" class="subtitle is-5">{{ getUser.phone.countryCode+''+getUser.phone.tel }}</h6> -->
                    <hr>
                    <b-menu>
                    <b-menu-list label="">
                        <b-menu-item xicon="account-outline" label="Profile" tag="router-link" to="/user" > 
                        </b-menu-item>
                        <!-- <b-menu-item icon="account-multiple-outline" tag="router-link" to="" label="Refer your friend"  ></b-menu-item> -->
                        <!-- <b-menu-item icon="credit-card" label="Payment" tag="router-link" to="/prescription" ></b-menu-item> -->
                        <b-menu-item xicon="help-circle-outline" label="Contact " tag="router-link" to="/contact" ></b-menu-item>
                        <b-menu-item xicon="information-outline" label="About " tag="router-link" to="/about" ></b-menu-item>
                        
                    </b-menu-list>
                    <hr class="m-2">
                    <b-menu-list label="">
                        <!-- <b-menu-item label="Account" tag="router-link" to="/account" ></b-menu-item> -->
                        <b-menu-item icon="logout" @click.native="logOut" label="Sign out" tag="a" ></b-menu-item>
                    </b-menu-list>

                    <b-menu-list label="">
                        <!-- <span class="px-2 py-4" style="position:absolute;bottom:0">&copy;  myHealthSusu. </span> -->
                    </b-menu-list>
                    </b-menu>
                </div>
        </div>
        <!-- <MainFooter/> -->
    </div>
</template>

<script>

    
    import userAuth from '../auth/index';
    import { userService } from '../services';
    import { mapGetters } from 'vuex';
    import MainFooter from '../components/footer'
    import SideNav from '../components/sidenav'

    export default {
        name: "ProfilePage",
        data() {
            return {
                // user: userAuth.getUser()
            }
        },
        components: {
            // MainFooter,
            SideNav
        },
        computed: {
            ...mapGetters('auth', ['getUser']),
        },
        methods: {
            logOut() {
                this.open = false;
                //
                userService.logout()
                //
                this.$router.push('/signin')
            }, 
            action() {
                
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>