import { render, staticRenderFns } from "./trips.vue?vue&type=template&id=19c12c3b"
import script from "./trips.vue?vue&type=script&lang=js"
export * from "./trips.vue?vue&type=script&lang=js"
import style0 from "./trips.vue?vue&type=style&index=0&id=19c12c3b&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports