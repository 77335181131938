
const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) :  false;
const initialState = user
    ? { status: { loggedIn: true }, user, address: '', location: null }
    : { status: {}, user: null, address: '', location: null };


import axios from 'axios';

export const auth = {
    namespaced: true,
    state: initialState,
    getters: {
        getUser(state) {
            return  state.user
        },
        address: (state) =>  {
            return { address: state.address, location: state.location }
        },
    },
    actions: {
        signin({ commit, state }, user){
            //
            commit('signin', user)
        },
        updateUser({ commit }) {
            // get latest update in local storage
            commit('updateUser')
        },
        addPlace({ state, commit }, data) {

            // console.log(data)
  
            if (typeof(data) === 'object' || data.address || data.location) {
              //
              commit('addPlace', data)

              //
              setTimeout(()=>{
                axios.post('/courier/location', { locationName: state.address, location: [ data.location.lat, data.location.lng ] }).then((resp)=> {
                
                    console.log(resp.data)
    
                  })
              }, 500)

                
            }
        },
        async updateLocation({ state, commit }, data) {

            // console.log(data)
  
            if (data.locationName && data.location) {
                 
                //   commit('addPlace', data)
                
              
            }
        },
    },
    mutations: {
        signin:  (state, user) => {
            state.status = { loggedIn: true }
            state.user = user
        },
        updateUser:  (state) => {
            if (state.status.loggedIn) {
                //
                const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) :  false;
                state.user = user
            }
        },
        addPlace: (state, data) => {
            // set
            state.address = data.address
            state.location = data.location
            // check before push
            // const found = state.addressList.find((place)=> place.address.includes(data.address))
  
            // if (!found) {
            // state.addressList.push(data)
            // }
            
          },
    }
}