<template>
    <div>
        <div class="sidebar-wrapper">
            <SideNav text=" 00.00" />
        </div>
        <div class="p-3">
            <div class="p-4 mt-2">
                <h4 class="title is-4 mb-1"> {{ getUser.firstname+' '+getUser.lastname }} </h4>
                <h6 class="is-5 mb-2 has-text-weight- has-text-grey is-7">{{ getUser.phone }}</h6>
                <b-tag :type="getUser.online ? 'is-primary' : 'is-dark'"> {{ getUser.online ?  'Online' : 'Offline' }} </b-tag>
            </div>
            <div v-if="false" class="p-3">
                <div class="card p-5">
                    <div class=" columns is-mobile">
                        <div class="column is-6">
                            <p class="title is-4 mb-1 has-text-primary"> GH₵ {{ getTotals(delivered).toFixed(2) }} </p>
                            <!-- <h4 class="subtitle is-5">Total </h4> -->
                            <h4 class=" is-s-ze-6 has-text-dark has-text-weight-medium">Total Wallet</h4>
                        </div>
                        <div class="column 	">
                            <p class="title is-4 mb-1 has-text-primary"> GH₵ {{ getTotals(delivered).toFixed(2) }} </p>
                            <!-- <h4 class="subtitle is-5">Total </h4> -->
                            <h4 class=" is-6 has-text-dark has-text-weight-medium"> Balance</h4>
                        </div>
                    </div>
                    <!-- <hr class="mt-3"> -->
                    <!-- <div class="columns is-mobile">
                        <div class="column is-half">
                            <div class="">
                                <p class="title is-5"> Pay Outs </p>
                                <h4 class="subtitle is-5">GHC0.00</h4>
                            </div>
                        </div>
                        <div class="column is-flex is-justify-content-right">
                            <div class=" ">
                                <p class="title is-5"> Pay Ins </p>
                                <h4 class="subtitle is-5">GHC0.00</h4>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="p-3">
                <div class="card p-5">
                    <div class="columns is-mobile">
                        <div class="column is-6">
                            <p class="title is-4 has-text-primary"> Today</p>
                            <h4 class="subtitle is-5 mb-0">Today's Earnings </h4>
                        </div>
                        <div class="column is-flex is-align-items-center is-justify-content-right">
                            <h4 class="has-text-weight-medium title is-6"> {{ numFormat(getTodayTotals(delivered)) }} </h4>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-3">
                <div class="card p-5">
                    <div class="columns is-mobile">
                        <div class="column is-6">
                            <p class="title is-4 has-text-primary"> Wallet</p>
                            <h4 class="subtitle is-5 mb-0">Total Earnings </h4>
                        </div>
                        <div class="column is-flex is-align-items-center is-justify-content-right">
                            <h4 class="has-text-weight-medium title is-6 "> {{ numFormat(getTotals(delivered)) }} </h4>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-3">
                <div class="card p-5">
                    <div class="columns is-mobile">
                        <div class="column is-6">
                            <p class="title is-4 has-text-"> Balance</p>
                            <h4 class="subtitle is-5">Total Balance</h4>
                        </div>
                        <div class="column is-flex is-align-items-center is-justify-content-right	">
                            <h4 class="has-text-weight-medium title is-6"> {{ getUser.payOuts ? numFormat(getTotals(delivered) - getUser.payOuts.reduce((a, b) => a + b.amount, 0)) : numFormat(getTotals(delivered)) }} </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-3">
                <div class="card p-5">
                    <div class="columns is-mobile">
                        <div class="column is-6">
                            <p class="title is-4 has-text-primary"> Pay outs</p>
                            <h4 class="subtitle is-5">Total payouts</h4>
                            <b-tag @click="$buefy.toast.open('Kindly contact Admin: Shopnscan')" type="is-primary">Request Payout </b-tag>
                        </div>
                        <div class="column is-flex is-align-items-center is-justify-content-right	">
                            <h4 class="has-text-weight-medium title is-6"> {{ getUser.payOuts ? numFormat(getUser.payOuts.reduce((a, b) => a + b.amount, 0)) : numFormat(0) }}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-3">
                <div class="card p-5">
                    <div class="columns is-mobile">
                        <div class="column is-6">
                            <p class="title is-4"> Bonus</p>
                            <h4 class="subtitle is-5">Total Bonuses</h4>
                        </div>
                        <div class="column is-flex is-align-items-center is-justify-content-right	">
                            <h4 class="has-text-weight-medium title is-6">{{ numFormat(0) }}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- <MainFooter/> -->
    </div>
</template>

<script>

            
    import { mapGetters, mapActions} from 'vuex'

    import MainFooter from '../components/footer'
    import SideNav from '../components/sidenav'
    import wNumb from 'wnumb'

    export default {
        data(){ return { 
            format: wNumb({ decimals: 2, thousand: ",", prefix: '₵ ', }),
        }},
        name: "EarningsPage",
        components: {
            // MainFooter,
            SideNav
        },
        computed: {

            ...mapGetters('orders', ['getOrders']),
            ...mapGetters('auth', ['getUser']),
            // ...mapGetters('orders', ['confirmed']),
            ...mapGetters('orders', ['delivered']),
            ...mapGetters('orders', ['balance']),
            // ...mapGetters('orders', ['cancelled']),
            
        },
        mounted() {
            //
            this.getAllOrders()
        },
        methods: {
            numFormat(amt){
                return this.format.to(amt)
            },
            getTotals(obj){
               const sum  = obj. reduce((accumulator, object) => {
                    return accumulator + object.deliveryFee;
                }, 0)

                return sum
            },
            getTodayTotals(obj){
               const sum  = obj.filter(o=> new Date().toJSON().substring(0, 10) == o.orderDelivered.substring(0, 10) ).reduce((accumulator, object) => {
                    return accumulator + object.deliveryFee;
                }, 0)

                return sum
            },
            alert(){
                console.log(this.$route)
            },
            async getAllOrders() {

                await this.$store.dispatch('orders/getOrders')

            }
        }
    }
</script>

<style lang="scss" scoped>
    // .card {
    //     border: 1px solid rgba(10, 10, 10, 0.1) !important;
    //     box-shadow: none;
    // }
</style>