<template>
    <div>
        <div class="p-3">
            <div class="p-3 px-4" >
                <h4 class="title is-3 sub">Contact us</h4>
                    <div class="content p-1">
                        <p class="title is-6 mb-2 sub">
                            Telephone
                        </p>
                        <div class="pb-5">
                            Support <a class="has-text-primary" href="tel:+233241026572">+233 24 102 6572</a><br>
                            Support <a class="has-text-primary" href="tel:+233552851642">+233 55 285 1642</a>  <br>
                            Operations <a class="has-text-primary" href="tel:+233243423195">+233 24 342 3195</a>  
                        </div>

                        <p class="title is-6 mb-2 sub">
                            Email
                        </p>    
                        <p class="mb-1"><a class="has-text-primary" href="mailto:hello@shopnscan.app"> hello@shopnscan.app</a> (Enquires)</p>
                        <p class="pb-5"><a class="has-text-primary" href="mailto:support@shopnscan.app"> support@shopnscan.app </a>(Support) </p>

                        <p class="title is-6 mb-2 sub">
                            Office
                        </p>    
                        <p class="pb-5">18 preserver, railway link, market highstreet,<br> Spintex, Accra, Ghaha</p>

                        <p class="title is-6 mb-2 sub">
                            Office hours
                        </p>    
                        <p class="pb-5">Mondays to Fridays: 9am to 4pm <br>
                        (Currently remote) </p>
                        
                    </div>
                <div style="position: absolute;bottom: 25px;">
                    <p class="is-size-7 has-text-grey mb-3">Shopnscan </p>
                    <p class="is-size-7 has-text-grey"> v1.0.1</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    
    export default {
        name: "ContactPage"
    }
</script>

<style lang="scss" scoped>

</style>