<template>
    <div>
        <!-- <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading> -->
        <div class=" p-5"  :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
            
            <feather size="30" @click="goBack" type="arrow-left" class="my-5 mx-2 dark" style="top:0;position:absolute" ></feather>
            <!-- <feather v-if="cartCount > 0" @click="emptyCart" size="30" type="trash" class="mx-5 mx-2 dark" style="right:0;position:absolute" ></feather> -->
            <h4 class="title is-4 ml-5 pl-5">Personal </h4>
            
            <!--  -->
            <!-- Total -->
            <div class="p-3 ">
                <div class="pt-5 pb-2">
                            <div class="columns is-mobile">
                                <div class="column">
                                    <b-field label="Firstname" label-position="on-border" :type="fn.type" :message="fn.message">
                                        <b-input @input="validateFname()" autofocus v-model="form.firstname" placeholder="Firstname"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <b-field label="Lastname" label-position="on-border" :type="ln.type" :message="ln.message">
                                        <b-input @input="validateLname()" autofocus v-model="form.lastname" placeholder="Lastname"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <b-field label="Email" label-position="on-border" message="">
                                        <b-input  v-model="form.email" placeholder="test@email.com"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <b-field label="Bio" label-position="on-border" message="">
                                        <b-input type="textarea" v-model="form.bio" placeholder="Bio"></b-input>
                                    </b-field>
                                </div>
                            </div>
                            <div class="columns is-mobile">
                                <div class="column">
                                    <b-field label="Phone" label-position="on-border" message="">
                                        <b-input disabled  v-model="form.phone" placeholder="Phone "></b-input>
                                    </b-field>
                                </div>
                            </div>

                            <b-field v-if="checkChanges" class="pt-1">
                                <b-button @click="onSubmit" rounded class="is-submit-button" expanded type="is-primary">Update</b-button>
                            </b-field>

                            <!-- {{ form }} -->
                        </div>
                <!-- <p class=""> :( No Free Coupons today</p> -->
            </div>

        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex"
    import { userService } from '../services';

    export default {
        name: "UserPage",
        data() {
            return {
                isLoading: false,
                isFullPage: true,
                screenWidth: 0,
                form: {
                    firstname: '',
                    lastname: '',
                    email: '',
                    phone: ''
                },
                fn: {
                    type:'',
                    message:'',
                },
                ln:{
                    type:'',
                    message:'',
                },
                show: true
            }
        },
        computed: {
            ...mapGetters('auth', ['getUser']),
            checkChanges() {

                if (this.form.firstname !== this.getUser.firstname || this.form.lastname !== this.getUser.lastname || this.form.email !== this.getUser.email){
                    //
                    return true
                }

                return false
            }
        },
        mounted() {
            // this.business = this.$store.getters['cart/getCartBusiness']
            this.updateScreenWidth();
            this.onScreenResize();

            //
            this.form.firstname = this.getUser.firstname
            this.form.lastname = this.getUser.lastname
            this.form.email = this.getUser.email
            this.form.phone = this.getUser.phone
        },
        methods: {
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            goBack() {
                this.$router.back()
            },
            validateFname() {
                if (this.form.firstname.length < 3){
                    this.fn.type = 'is-danger';
                    this.fn.message ='Enter 3-characters or above'
                    return false
                }

                if (this.form.firstname.length >= 3){
                    this.fn.type = 'is-success';
                    this.fn.message =''
                    return true
                }
            },
            validateLname() {
                if (this.form.lastname.length < 3){
                    this.ln.type = 'is-danger';
                    this.ln.message ='Enter 3-characters or above'
                    return false
                }

                if (this.form.lastname.length >= 3){
                    this.ln.type = 'is-success';
                    this.ln.message =''
                    return true
                }
            },
            openLoading() {
                this.isLoading = true
                setTimeout(() => {
                    this.isLoading = false
                }, 1.5 * 1000)
            },
            onSubmit(event) {
                // validate
                if ( !this.validateFname() || !this.validateLname()){
                    return
                }

                this.isLoading = true
                
                // ajax
                this.axios.post('/courier/update', this.form).then(response=>{
                    //
                    console.log(response.data)
                    // 
                    this.$buefy.toast.open(response.data)

                    // 
                    userService.getAll().then(() => {
                        // update
                        this.$store.dispatch('auth/updateUser')
                    })

                }).catch(error=>{
                    //
                    this.isLoading = false
                    //
                    console.log(error)
                    //
                    this.$buefy.toast.open(error.response.data)
                })
                // alert(JSON.stringify(this.form))
            },
            onReset(event) {
                event.preventDefault()
                // Reset our form values
                this.form.email = ''
                this.form.name = ''
                this.form.food = null
                this.form.checked = []
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                this.show = true
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .link:hover, .link:focus {
        background: grey;
        background: #f4f4f4;
        border-radius: 1.2rem;
    }
</style>